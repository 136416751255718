@import "../../../assets/styles/global.scss";
.quiz-container {
    width: 100%;
    .radio-btns {
        width: 100%;
        div {
            width: 100%;
            label {
                border: 1.5px solid #102c50;
                margin: 0;
                border-radius: 10px;
                margin-bottom: 10px;
                height: 50px;
                &:hover{
                    background-color: #101F31;
                }
                span {
                    font-family: OnestMedium;
                    color: #b5c0cf;
                    & > span {
                        color: #2a5b96;
                    }
                }
            }
        }
    }

    .selected-option {
        background-color: #082c5a;
        span {
            color: #f0f6fe !important;
            & > span {
                color: #1276f5 !important;
            }
        }
    }
}

.action-buttons {
    // width: 300px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    position: absolute;
    top: 0px;
    width: 120%;
    margin-left: -10%;

    @include mobile {
        position: initial;
        margin-left: 0;
        width: 100%;
    }
}