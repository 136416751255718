@import "./assets/styles/global.scss";

@font-face {
  font-family: "OnestRegular";
  src:
    local("OnestRegular"),
    url("./assets/fonts/onest/Onest-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "OnestMedium";
  src:
    local("OnestMedium"),
    url("./assets/fonts/onest/Onest-Medium.ttf") format("truetype");
  font-weight: 500;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  font-family: OnestRegular;
  background-color: #121d2b;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

:root {
  --main-width: 650px;
  // --main-width: 60%;
}

.container {
  width: 100%;
  margin: 20px auto 100px;
  border: 1px solid #e3e3e3;
  padding: 20px;
  background: white;
  border-radius: 10px;
  // min-height: 400px;
}

.step-content {
  display: flex;
  // padding: 20px;
  min-height: 200px;
  position: relative;
}

.kyc-container {
  width: var(--main-width);
  position: relative;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box !important;
  @include mobile {
    width: 100%;
    padding: 34px 16px 16px 16px;
    box-sizing: border-box !important;
  }
}


.step-container {
  width: 100%;
  height: 100%;

  button.Mui-disabled {
    cursor: not-allowed !important;
    color: #fff;
    opacity: 0.2;
    @include mobile {
      margin-top: 16px;
      width: 100%;
    }
    //border: 1.5px solid #ccd3dd !important;
  }
}
.loader-content {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  background: rgba(000,000,000, .5);
}
.next-step-btn {
  float: right;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
  &:hover {
    background-color: #0067ec !important;
  }
  &:active {
    background-color: #045ed3 !important;
  }
  @include next-button;
  @include mobile {
    padding: 8px 8px 8px 8px !important;
  }

  &.disabled-btn {
    background-color: #1276f5 !important;
    opacity: 0.2;
  }
}



@media screen and (max-width: 1000px) {
  :root {
    --main-width: 90%;
  }
}
// .preloader-rain{
//   background-image: url("./images/preloader-rain.gif");
//   background-position: center;
//   background-repeat: no-repeat;
//   width: 210px;
//   height: 210px;
//   border-radius: 100%;
//   transform: scale(0.4);
// }