@import "../../../assets/styles/global.scss";
ul {
    background-color: #0e1824 !important;
    border: 1px solid #1f2b39;
    border-radius: 4px;
    padding: 0px !important;
    li {
        font-family: OnestRegular !important;
        font-size: 14px !important;
        line-height: 36px !important;
        color: #ffffff !important;
        border-bottom: 1px solid #1f2b39 !important;
    }
}
.prestep-review-container {
    .success-subtitle {
        text-align: center;
        color: #b5c0cf;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        margin-top: 5px;
        margin-bottom: 40px;
        span {
            display: block;
        }
    }
    .preview-form {
        display: flex;
        flex-direction: column;
        max-width: 350px;
        margin: auto;
        input {
            color: #6d7a8c;
        }
        label.age-label {
            margin: 0;
            top: 18px;
            background: #0e1824;
            border-radius: 10px;
            transition: 0.5;
            &.t0 {
                top: 0px !important;
            }
            //    > span > span svg {
            //     color: #B5C0CF;
            //    }
        }
        .selected-radio {
            span {
                font-family: OnestMedium;
            }
        }
        span {
            font-family: OnestRegular;
            font-size: 18px;
            font-style: normal;
            color: #b5c0cf;
            // font-weight: 500;
            line-height: 21px;
            padding: 0;
            margin-right: 2px;
        }
        .selected {
            span svg {
                color: #2f80ed !important;
            }
        }
        .radio-container {
            & > div label {
                margin: 0px;
            }
            & > div {
                display: flex;
                justify-content: space-between;
                margin: 16px 0px 28px;
            }
        }
        .age-label {
            color: #6d7a8c;

            font-family: OnestRegular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .select-age {
            margin-bottom: 16px;
            & > div {
                border-radius: 8px;
                border: 1px solid #1f2b39;
                background: #0e1824;
                color: #F0F6FE;
                padding-bottom: 9px;
                padding-top: 23px;
            }
            span {
                color: #6d7a8c;
            }
            &.Mui-focused {
                & > div {
                    border: 1px solid #1276f5;
                }
            }
            svg {
                color: #b5c0cf;
            }
            fieldset {
                border: none;
            }
        }

        .email-input {
            border-radius: 8px;
            border: 1px solid #1f2b39;
            background: #0e1824;
            flex: 1;
            margin-bottom: 20px;
            label {
                color: #6d7a8c;
            }
            div.Mui-focused {
                fieldset {
                    border: 1px solid #1276f5;
                }
            }
        }
        .send-button {
            width: 125px;
            display: block;
            margin: auto;
            line-height: 36px;
            font-family: OnestRegular;
            text-transform: capitalize;
            font-size: 16px;
        }
    }
    .radio-title {
        color: #b5c0cf;
        font-family: OnestRegular;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
    }
    .success-container {
        margin-top: 200px;

        .customer-status {
            text-align: center;
            font-size: 18px;
            margin-top: 20px;
            margin-bottom: 10px;
        }
        .email-input {
            border-radius: 8px;
            border: 1px solid #1f2b39;
            background: #0e1824;
            flex: 1;
            margin-bottom: 20px;
            label {
                color: #6d7a8c;
            }
        }
        .send-email-container {
            display: flex;

            .entered input {
                color: #4395ff;
            }
            .done input {
                color: #f0f6fe;
            }
            .error input {
                color: #f13c3c;
            }

            input {
                // font-weight: 600;
                font-size: 16px;
                font-family: OnestRegular;
            }
            button {
                margin-left: 10px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                text-transform: capitalize;
                padding: 12px 22px 11px 22px;
                font-family: OnestRegular;
                width: 120px;

                @include mobile {
                    margin-left: 0;
                    margin-top: 16px;
                    padding: 12px 18px 11px 18px;
                    width: 100%;
                }
            }

            @include mobile {
                flex-direction: column;
            }
        }
        
        @include mobile {
            margin-top: 28px;
        }
    }
    .erorr-text-container {
        text-align: center;
        margin-top: -10px;
        margin-bottom: 10px;
        & > div {
            font-size: 12px;
            display: flex;
            justify-content: center;
            label {
                margin-left: 5px;
            }
        }
    }

    .error-content {
        color: #f13c3c;
        font-size: 12px;
        margin-left: 5px;
    }
    .success-content {
        color: #18b039;
        font-size: 16px !important;
        align-items: baseline;
        margin-left: 5px;
    }
    .sended-and-disabled {
        color: #e3e3e3 !important;
        &.Mui-disabled {
            background-color: #1976d2 !important;
            opacity: 0.2;
        }
    }
}
