@mixin next-button {
    padding: 10px !important;
    border-radius: 4px !important;
    background: #2F80ED ;
    font-family: OnestMedium !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    box-shadow: none !important;
    text-transform: none !important;
    width: 50px;
    height: 50px;
    min-width: unset !important;

    @include mobile {
        font-size: 12px !important;
    }
}
