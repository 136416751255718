@import "../../../assets/styles/global.scss";

.form-section {
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
    
    &.disabled {
        @include form-section-disabled;
    }

    .form-section-content {
        padding: 20px 0px;
        // display: flex;
        box-sizing: border-box;
        width: 100%;
        color: #fff;
    }

    .section-title {
        font-family: OnestMedium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        line-height: 25.2px;
        padding: 0px 0px 20px 0px;
    }
    .no-padding {
        padding: 0;
    }
}
.step-container{
    .form-section-content {
        min-height: 224px;
    }
}
