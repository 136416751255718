@import "../../../assets/styles/global.scss";



.stepper-container {
  display: flex;
  margin: auto;
  border-radius: 10px;
  padding: 210px 0px 20px;
  flex-direction: column;
  @include mobile {
    padding: 0;
  }
}
.step {
  padding: 0px 3px 0px 0px !important;
  .step-label {
    & > span {
      background: #17293f;
      height: 2px;
      width: 100%;
      svg {
        display: none;
      }
    }
  }
  & > div {
    display: none;
  }
  &.step-completed {
    span {
      background-color: #1276f5 !important;
    }
  }
}
.stepper-stack {
  width: 100%;
}
.stepper-container div > span > span > span {
  margin-top: 0px !important;
}
#root .label-step {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

#root .label-title {
  font-weight: 400;
}

#root .label-btn {
  border-radius: 20px;
  padding: 3px 9px;
  width: fit-content;
  font-size: 14px;
  margin: 5px auto;
}

#root .btn-in-progress {
  background-color: #1476cf33;
  color: #1476cf;
}

#root .btn-pending {
  border: 1px solid #a6aaad;
  color: rgba(0, 0, 0, 0.6);
}

#root .btn-completed {
  background-color: #21bb7833;
  color: #21bb77;
}

body {
  margin: 0px;
}

@media screen and (max-width: 500px) {
 
  #root .label-btn,
  #root .label-title {
    display: none;
  }
  .nav-block {
    display: none !important;
  }

  .stepper-steps-container {
    width: 100%;
    & > div {
      padding: 4px;
    }
  }

  .user-data-container > div {
    flex-direction: column;
    & > div {
      margin-bottom: 20px;
    }
  }
  h5 {
    font-size: 19px !important;
  }
  .page-fourth-container {
    flex-direction: column;
    .paper-details {
      width: 100%;
      box-sizing: border-box;
    }
  }
  
  .modal-content {
    width: 80% !important;
  }
  .top-block,
  .bottom-block {
    flex-direction: column;
  }
}

.label-title {
  font-family: OnestMedium !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22.4px !important; /* 140% */
  margin-top: 12px !important;
}
.step-counter {
  font-family: OnestMedium !important;
  color: #b5c0cf;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
}
