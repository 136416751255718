@import "../../../assets/styles/global.scss";

.action-buttons {
    &.fl-end {
        justify-content: flex-end !important;
    }
    .disabled-btn {
        color: #fff !important;
    }
    
    .rotate {
        transform: rotate(180deg);
    }
}
