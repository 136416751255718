@import "../../../assets/styles/global.scss";



.header {
    background-color: #121d2b;
    border-radius: 10px;
    margin-bottom: 100px;
    margin-top: 50px;
    align-items: center;
    display: flex;
    top: 0px;
    width: 120%;
    margin-left: -10%;
    position: absolute;
    
    .logo-container{
        display: flex;
        svg{
            margin-right: 5px;
        }
    }
    .icon-rain{
        width: 32px;
        height: 50px;
        background-image: url("./icon-logo-rain.png");
        background-repeat: repeat-x;
        background-position: 0px center;
    }
    .icon-rain.move{
        transition: 1s;
        background-position: -33px center;
    }
    & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    h3,
    h6 {
        margin-top: 0px !important;
        font-family: OnestRegular;
    }
    
    @include mobile {
        width: 100%;
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 40px;
        position: initial;
    }

    .select-lang-container {
        margin: 0px 5px;
        background-color: #162639;
        svg {
            color: #fff;
        }
        > div {
            color: #fff;
            font-family: OnestRegular;
        }
        @include mobile {
            & > div {
                svg {
                    display: none;
                }
                & > div {
                    padding: 5px !important;
                }
            }
        }
    }
    .form-section-content {
        text-align: right;
        font-family: OnestRegular;
    }
}
