@import "../../assets/styles/global.scss";
.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60%;
    h1 {
        text-align: center;
        color: #fff;
        font-family: OnestRegular;
        font-size: 18px;
        font-style: normal;
    }
    svg {
        width: 100px;
        height: 100px;
    }
    .send-button {
        text-transform: none;
        font-family: OnestRegular;
        margin-top: 20px;
    }
}
