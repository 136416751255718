@import "../../../assets/styles/global.scss";

.intro-container {
    margin-top: 200px;

    .intro-wrapper {
        height: 425px;
        width: 100%;
        background-image: url("../../../assets/images/vector-2.svg");
        background-position: 50% 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .intro-title {
            color: #F0F6FE;
            text-align: center;
            font-family: OnestRegular;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 43px;

            @include mobile {
                font-size: 24px;
                line-height: 33px;
            }
        }

        .intro-text {
            color: #B5C0CF;
            text-align: center;
            font-family: OnestRegular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            margin-top: 24px;
            margin-bottom: 40px;
        }

        button {
            padding: 12px 22px 11px 22px; 
            width: 110px;
        }

        @include mobile {
            height: 100%;
            background-size: contain;
            background-position: 100% 50%;
        }
    }
    
    @include mobile {
        margin-top: 100px;
    }
}
