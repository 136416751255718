@import "../../../assets/styles/global.scss";

.success-title {
    text-align: center;
    color: #f0f6fe;
    font-size: 21px;
    margin-bottom: 30px;
}
.success-subtitle {
    text-align: center;
    color: #b5c0cf;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-top: 5px;
    span {
        display: block;
    }
}
.go-home {
    display: flex !important;
    align-content: center;
    margin: 0 auto !important;
    font-family: OnestMedium !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    text-transform: none !important;
}
.success-container {
    margin-top: 200px;

    .customer-status {
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .email-input {
        border-radius: 8px;
        border: 1px solid #1f2b39;
        background: #0e1824;
        flex: 1;
        label {
            color: #6d7a8c;
        }
    }
    .send-email-container {
        display: flex;

        .entered input {
            color: #4395ff;
        }
        .done input {
            color: #f0f6fe;
        }
        .error input {
            color: #f13c3c;
        }

        input {
            font-weight: 600;
            font-size: 16px;
            font-family: OnestRegular;
        }
        button {
            margin-left: 10px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            text-transform: capitalize;
            padding: 12px 22px 11px 22px;
            font-family: OnestRegular;
            width: 120px;

            @include mobile {
                margin-left: 0;
                margin-top: 16px;
                padding: 12px 18px 11px 18px;
                width: 100%;
            }
        }

        @include mobile {
            flex-direction: column;
        }
    }
    .erorr-text-container {
        text-align: center;
        margin-top: 10px;
        & > div {
            font-size: 12px;
            display: flex;
            justify-content: center;
            label {
                margin-left: 5px;
            }
        }
    }

    @include mobile {
        margin-top: 28px;
    }
}

.circle-container {
    position: relative;
    width: fit-content;
    margin: 0 auto;

    &.red circle {
        color: #f34b27;
    }
    &.yellow circle {
        color: #f4c10d;
    }
    &.green circle {
        color: #18b039;
    }
    circle {
        stroke-linecap: round;
    }
    .border-custom-background {
        border: 11px solid #17293f;
        border-radius: 100%;
        position: absolute;
        width: 99%;
        height: 96%;
        top: 0px;
        box-sizing: border-box;
    }
}
.error-content {
    color: #f13c3c;
    font-size: 12px;
    margin-left: 5px;
}
.success-content {
    color: #18b039;
    font-size: 16px !important;
    align-items: baseline;
    margin-left: 5px;
}
.sended-and-disabled {
    color: #e3e3e3 !important;
    &.Mui-disabled {
        background-color: #1976d2 !important;
        opacity: 0.2;
    }
}
.emulate-container {
    width: 100%;
    display: block;
    text-align: center;
    background: #1d3c64;
    margin: 0;
    border-radius: 10px;
    margin-top: 150px;
    border-top: 1px solid #fff;
    label {
        width: 100%;
        text-align: center;
        display: block;
    }
}
.point-count {
    color: #f0f6fe;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.red {
        color: #f34b27;
    }
    &.yellow {
        color: #f4c10d;
    }
    &.green {
        color: #18b039;
    }
}
